if(!!window.Trix){
  reinitTrixToolbar();
}
function reinitTrixToolbar(){
  let lang = Trix.config.lang;
  Trix.config.toolbar = {
    getDefaultHTML: function() {
      return `
      <div class="trix-button-row">
        <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
          <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="${lang.bold}" tabindex="-1">${lang.bold}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="${lang.italic}" tabindex="-1">${lang.italic}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-strike" data-trix-attribute="strike" title="${lang.strike}" tabindex="-1">${lang.strike}</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="${lang.link}" tabindex="-1">${lang.link}</button>
        </span>
        <span class="trix-button-group trix-button-group--block-tools" data-trix-button-group="block-tools">
          <button type="button" class="trix-button trix-button--text" data-trix-attribute="heading1" title="H1" tabindex="-1">H1</button>
          <button type="button" class="trix-button trix-button--text" data-trix-attribute="heading2" title="H2" tabindex="-1">H2</button>
          <button type="button" class="trix-button trix-button--text" data-trix-attribute="heading3" title="H3" tabindex="-1">H3</button>
          <button type="button" class="trix-button trix-button--text" data-trix-attribute="heading4" title="H4" tabindex="-1">H4</button>
          <button type="button" class="trix-button trix-button--text" data-trix-attribute="heading5" title="H5" tabindex="-1">H5</button>
          <button type="button" class="trix-button trix-button--text" data-trix-attribute="heading6" title="H6" tabindex="-1">H6</button>
        </span>
        <span class="trix-button-group-spacer"></span>
        <span class="trix-button-group trix-button-group--history-tools" data-trix-button-group="history-tools">
          <button type="button" class="trix-button trix-button--icon trix-button--icon-undo" data-trix-action="undo" data-trix-key="z" title="Undo" tabindex="-1" disabled="">Undo</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-redo" data-trix-action="redo" data-trix-key="shift+z" title="Redo" tabindex="-1" disabled="">Redo</button>
        </span>
      </div>
      <div class="trix-button-row">
        <span class="trix-button-group trix-button-group--block-tools">
          <button type="button" class="trix-button trix-button--icon trix-button--icon-image" data-trix-attribute="image" data-trix-action="image" title="Image" tabindex="-1">Image</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-quote" data-trix-attribute="quote" title="Quote" tabindex="-1">Quote</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-code" data-trix-attribute="code" title="Code" tabindex="-1">Code</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="Bullets" tabindex="-1">Bullets</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" title="Numbers" tabindex="-1">Numbers</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-decrease-nesting-level" data-trix-action="decreaseNestingLevel" title="Decrease Level" tabindex="-1" disabled="">Decrease Level</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-increase-nesting-level" data-trix-action="increaseNestingLevel" title="Increase Level" tabindex="-1" disabled="">Increase Level</button>
        </span>
      </div>
      <div class="trix-dialogs" data-trix-dialogs>
        <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
          <div class="trix-dialog__link-fields">
            <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="${lang.urlPlaceholder}" aria-label="${lang.url}" required data-trix-input>
            <div class="flex">
              <input type="button" class="btn btn-secondary btn-small mr-1" value="${lang.link}" data-trix-method="setAttribute">
              <input type="button" class="btn btn-tertiary outline btn-small" value="${lang.unlink}" data-trix-method="removeAttribute">
            </div>
          </div>
          <div data-behavior="embed_container">
            <div class="link_to_embed link_to_embed--new" style='margin: 0px 5px;'>
              <span>
                <?xml version="1.0"?>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" style="width: 20px;">
                    <path style="line-height:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;block-progression:tb;isolation:auto;mix-blend-mode:normal" d="M 4.5 1.0605469 L 1.0214844 4.5390625 L 1.7285156 5.2460938 L 4 2.9746094 L 4 4.1132812 C 4 9.0145612 7.9854386 13 12.886719 13 L 14 13 L 14 12 L 12.886719 12 C 8.5259985 12 5 8.4740013 5 4.1132812 L 5 2.9746094 L 7.2714844 5.2460938 L 7.9785156 4.5390625 L 4.5 1.0605469 z M 4.5 2.4746094 L 4.5253906 2.5 L 4.4746094 2.5 L 4.5 2.4746094 z" font-weight="400" font-family="sans-serif" white-space="normal" overflow="visible"/>
                </svg>
              </span>
              Would you like to embed media from this site?
              <input class="button button-hollow button-small ml-3" type="button" data-behavior="embed_url" value="Yes, embed it" style='padding: 2px 5px;'>
            </div>
          </div>
        </div>
      </div>
    `
    }
  }
}

class EmbedController {
  constructor(element) {
    this.pattern = /^https:\/\/([^\.]+\.)?youtube\.com\/watch\?v=(.*)/

    this.element = element
    this.editor = element.editor
    this.toolbar = element.toolbarElement
    this.hrefElement = this.toolbar.querySelector("[data-trix-input][name='href']")
    this.embedContainerElement = this.toolbar.querySelector("[data-behavior='embed_container']")
    this.embedElement = this.toolbar.querySelector("[data-behavior='embed_url']")

    this.reset()
    this.installEventHandlers()
  }

  installEventHandlers() {
    this.hrefElement.addEventListener("input", this.didInput.bind(this))
    this.hrefElement.addEventListener("focusin", this.didInput.bind(this))
    this.embedElement.addEventListener("click", this.embed.bind(this))
  }

  didInput(event) {
    let value = event.target.value.trim()
    let matches = value.match(this.pattern)
    console.log(value,matches)

    // When patterns are loaded, we can just fetch the embed code
    if (matches != null) {
      this.fetch(matches[2])

    // No embed code, just reset the form
    } else {
      this.reset()
    }
  }

  fetch(value) {
    value = value.split('&')[0]
    $.ajax({
      url: `/youtube/${encodeURIComponent(value)}`,
      type: 'get',
      error: this.reset.bind(this),
      success: this.showEmbed.bind(this)
    })
  }

  embed(event) {
    if (this.currentEmbed == null) { return }

    let attachment = new Trix.Attachment(this.currentEmbed)
    this.editor.insertAttachment(attachment)
    this.element.focus()
  }

  showEmbed(embed) {
    this.currentEmbed = embed
    this.embedContainerElement.style.display = "block"
  }

  reset() {
    this.embedContainerElement.style.display = "none"
    this.currentEmbed = null
  }
}

document.addEventListener("trix-initialize", function(event) {
  new EmbedController(event.target)
})
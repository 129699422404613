import "@fortawesome/fontawesome-free/js/all"
import "./javascript/trix_override"

$(document).on('click touchstart', '.fa-times', function(){
  $('#notifications').remove();
});

$( document ).ready(function() {
  if($(".contact.bg-page .page-wrapper")[0]){
    $(".contact-us-background").css("height", $(".contact.bg-page .page-wrapper")[0].clientHeight)
  }
})